import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/search',
    name: 'search',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import('../views/search.vue')
    },
  },
  {
    path: '/redrock',
    name: 'redrock',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import('../views/redRock.vue')
    },
  },
  {
    path: '/street',
    name: 'street',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import('../views/street.vue')
    },
  },
  {
    path: '/community',
    name: 'community',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import('../views/community.vue')
    },
  },
  {
    path: '/gridMan',
    name: 'gridMan',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import('../views/gridMan.vue')
    },
  },
  {
    path: '/children',
    name: 'children',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import('../views/children.vue')
    },
  },
  
]
const router = new VueRouter({
  routes,
  mode: "history",
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '河边镇“党建引领 基层治理”积分管理平台'; // 设置网页标题
  next();
});
export default router
